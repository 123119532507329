import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Information.styles';
import Button from '../../../components/Button/Button';
import ModalTwoFactor from '../../../components/TwoFactor/ModalTwoFactor';

import { useTheme } from 'styled-components';
import { CheckCircle, DeniCircle } from '../../../assets/icon';
import { useDispatch } from 'react-redux';
import {
  pixTransactionAproveFetchAction,
  pixTransactionNegadedFetchAction,
} from '../../../store/fetchActions/pixTransactionsActions';
import { useForm } from 'react-hook-form';

export default function InformationPix({ title, data, color, bold, idTransaction, showButton, onClose }) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('');
  const [disableButon, setDisableButon] = useState(false);
  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.buttonNegadedText);
  const [token, setToken] = useState('');
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [isAprove, setIsAprove] = useState(false);

  const { handleSubmit: handleToken, control: tokenControl } = useForm({});

  const handleDataForm = (data) => {
    setShowTwoFactorModal(false);

    if (isAprove) return aproved(data);

    return deny(data);
  };

  const deny = (data) => {
    dispatch(
      pixTransactionNegadedFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const aproved = (data) => {
    setDisableButon(true);
    dispatch(
      pixTransactionAproveFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      {typeof data === 'string' ? (
        <Styled.ParagraphName>{data}</Styled.ParagraphName>
      ) : (
        <>
          {data?.map((data, index) => {
            return (
              <Styled.RowContainer key={index} role={'information-row'}>
                <Styled.ParagraphName>{data.name}</Styled.ParagraphName>
                <Styled.ParagraphValue color={color} bold={bold}>
                  {data.value}
                </Styled.ParagraphValue>
              </Styled.RowContainer>
            );
          })}
          {showButton && (
            <Styled.ContainerButton>
              <Button
                backgroundcolor={theme.colors.buttonNegaded}
                width="20vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonNegadedHover}
                hovercolor={theme.colors.buttonNegadedHoverText}
                mycolor={theme.colors.buttonNegadedText}
                onClick={() => {
                  setShowTwoFactorModal(true);
                  setIsAprove(false);
                }}
                onMouseOver={() => setHoverColorDeni(theme.colors.buttonNegadedHoverText)}
                onMouseOut={() => setHoverColorDeni(theme.colors.buttonNegadedText)}
                disabled={disableButon}
              >
                <DeniCircle color={hoverColorDeni} />
                <span>Negar</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonApproved}
                width="20vw"
                height="3.125vw"
                hoverbgcolor={theme.colors.buttonApprovedHover}
                hovercolor={theme.colors.buttonApprovedHoverText}
                disable={disableButon}
                mycolor={theme.colors.buttonApprovedText}
                onClick={() => {
                  setShowTwoFactorModal(true);
                  setIsAprove(true);
                }}
                type="submit"
              >
                <CheckCircle />
                <span>Aprovar</span>
              </Button>
            </Styled.ContainerButton>
          )}
        </>
      )}
      {showTwoFactorModal && (
        <ModalTwoFactor
          open={showTwoFactorModal}
          onClose={() => setShowTwoFactorModal(false)}
          handleToken={handleToken}
          onSubmit={(data) => handleDataForm(data)}
          control={tokenControl}
        />
      )}
    </Styled.ContainerStyled>
  );
}

InformationPix.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node,
  idTransaction: PropTypes.string,
};
