import dayjs from 'dayjs';
import api from '../../utils/services/api';

import {
  detailAccountsActionError,
  detailAccountsActionLoading,
  detailAccountsActionSuccess,
  approvedAccountsPendingActionError,
  approvedAccountsPendingActionLoading,
  approvedAccountsPendingActionSuccess,
  deniedAccountsActionError,
  deniedAccountsActionLoading,
  deniedAccountsActionSuccess,
  blockAccountActionError,
  blockAccountActionLoading,
  blockAccountActionSuccess,
  unlockAccountActionError,
  unlockAccountActionLoading,
  unlockAccountActionSuccess,
  detailAccountsDocumentError,
  detailAccountsDocumentLoading,
  detailAccountsDocumentSuccess,
  balanceAccountsActionError,
  balanceAccountsActionLoading,
  balanceAccountsActionSuccess,
} from '../ducks/account';
import { hideAlert, showAlert } from '../ducks/sweetAlert';
import { pendingAccountsFetchAction, blockedAccountsFetchAction } from './userAccounts';

export const detailAccountsFetchAction = (idConta) => {
  return async (dispatch) => {
    dispatch(detailAccountsActionLoading());
    try {
      const response = await api.get(`/conta-bacen/${idConta}/detalhes`);
      dispatch(detailAccountsActionSuccess(response.data));
    } catch (error) {
      dispatch(detailAccountsActionError(error?.response?.data));
    }
  };
};

export const detailAccountsFetchActionReset = () => {
  return async (dispatch) => {
    dispatch(detailAccountsActionLoading());
    try {
      dispatch(detailAccountsActionSuccess([]));
    } catch (error) {
      dispatch(detailAccountsActionError(error));
    }
  };
};

export const detailAccountsDocumentFetchAction = (idConta) => {
  return async (dispatch) => {
    dispatch(detailAccountsDocumentLoading());
    try {
      const response = await api.get(`/documentos/${idConta}/pendente`);
      dispatch(detailAccountsDocumentSuccess(response.data));
    } catch (error) {
      dispatch(detailAccountsDocumentError(error?.response?.data));
    }
  };
};

export const approvedAccountsFetchAction = (param, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(pendingAccountsFetchAction());
    };
    dispatch(approvedAccountsPendingActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/conta-bacen/new-account/approve`, param);
      dispatch(approvedAccountsPendingActionSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Conta aprovado com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(approvedAccountsPendingActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const deniedAccountsFetchAction = (param, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(pendingAccountsFetchAction());
    };
    dispatch(deniedAccountsActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`/conta-bacen/new-account/deny`, param);
      dispatch(deniedAccountsActionSuccess(response.data));

      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Conta negada com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(deniedAccountsActionError(error?.response?.data));
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const blockAccountFetchAction = (idConta, params, callback) => {
  return async (dispatch) => {
    dispatch(blockAccountActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/conta-bacen/${idConta}/block`, params);
      dispatch(blockAccountActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
        }),
      );
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage,
          icon: 'error',
        }),
      );
      dispatch(blockAccountActionError(error?.response?.data));
    }
  };
};

export const unlockAccountFetchAction = (idConta, params, callback) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
      dispatch(blockedAccountsFetchAction());
    };
    dispatch(unlockAccountActionLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.put(`/conta-bacen/${idConta}/unblock`, params);
      dispatch(unlockAccountActionSuccess(response.data));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
          func: closeModals,
        }),
      );
      callback();
    } catch (error) {
      const errorMessage = error?.response?.data?.mensagem;
      dispatch(
        showAlert({
          title: 'Ops...',
          text: errorMessage,
          icon: 'error',
          func: closeModals,
        }),
      );
      dispatch(unlockAccountActionError(error?.response?.data));
    }
  };
};

export const balanceAccountsFetchAction = (idConta, callback) => {
  return async (dispatch) => {
    dispatch(balanceAccountsActionLoading());
    try {
      const response = await api.get(`/conta-bacen/${idConta}/client-balance-admin`);
      dispatch(balanceAccountsActionSuccess(response.data));
      callback(response.data);
    } catch (error) {
      dispatch(balanceAccountsActionError(error?.response?.data));
      callback(error?.response?.data);
    }
  };
};

export const exportAccountsFetchAction = (filtro) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `/transacao/exportar/saldoContas?PAGE=${filtro.PAGE ?? ''}&LIMIT=${filtro.LIMIT ?? ''}&SEARCH=${
          filtro?.SEARCH ?? ''
        }&DATA_INICIAL=${filtro?.DATA_INICIAL ?? ''}&DATA_FINAL=${filtro?.DATA_FINAL ?? ''}`,
        { responseType: 'blob' },
      );
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `contas_aprovadas_${dayjs().format('DD/MM/YYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel gerar o relatório solicitado',
          icon: 'error',
        }),
      );
    }
  };
};
