import { useSelector, useDispatch } from 'react-redux';
import * as Styled from '../../styles/ContasAprovados.styles';
import { OptionsContainer, OptionsContainerProfiles } from '../../styles/GerenciarContas.styles';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { detailAccountsFetchAction } from '../../../../store/fetchActions/accountActions';
import { cobansFetchAction } from '../../../../store/fetchActions/cobansActions';
import TextField from '../../../../components/TextField';
import Loading from '../../../../components/Loading';
import InputMask from '../../../../components/InputMask';
import SelectInput from '../../../../components/SelectOptions';
import { brasilStateOptions } from '../../../../utils/constant';
import dayjs from 'dayjs';

export default function UserDetail({ contas, info }) {
  const dispatch = useDispatch();

  useMemo(() => {
    if (contas?.ID_CONTA !== undefined) {
      dispatch(detailAccountsFetchAction(contas?.ID_CONTA));
      dispatch(cobansFetchAction());
    }
  }, [contas?.ID_CONTA]);

  const { usersData } = useSelector((state) => state.user);
  const { contato, endereco, cliente } = useSelector((state) => state.account.detailAccountsData);
  const { loadingDetailAccounts } = useSelector((state) => state.account);
  const { cobansData, loadingCobans } = useSelector((state) => state.cobans);

  const perfisArray = useMemo(() => cobansData?.filter((val) => val.CPFCNPJ === null), [cobansData]);
  const perfis = useMemo(() => perfisArray?.filter((val) => val.NOME === usersData.NOMECOBAN), [perfisArray]);

  const { control } = useForm();

  return (
    <>
      {loadingDetailAccounts || loadingCobans ? (
        <Loading />
      ) : (
        <form>
          <Styled.InformationContainer>
            <h3>SOBRE</h3>
            <Styled.Container width={'29.306vw'} margin={'1.389vw 0 0 0'}>
              <TextField
                label={'Nome/Razão Social'}
                disabled={true}
                name={'NOME'}
                width={'29.306vw'}
                control={control}
                value={cliente?.NOME}
                spacelabelinput={'0.4vw'}
              />
            </Styled.Container>

            <Styled.Row>
              <Styled.Container width={'14.958vw'} margin={'1.389vw 0.3vw 0 0'}>
                <InputMask
                  name="CPFCNPJ"
                  disabled={true}
                  mask={cliente?.CPFCNPJ?.length < 12 ? '999.999.999-999' : '99.999.999/9999-99'}
                  label={'CPF/CNPJ'}
                  width={'14vw'}
                  value={cliente?.CPFCNPJ}
                  control={control}
                  spacelabelinput={'0.4vw'}
                />
              </Styled.Container>
              <Styled.Container width={'13.958vw'} margin={'1.389vw 0vw 0 0'}>
                <InputMask
                  name="TELEFONE"
                  disabled={true}
                  mask={'(99) 99999-9999'}
                  label={'Telefone'}
                  width={'13.958vw'}
                  value={contato?.FONECEL}
                  control={control}
                  spacelabelinput={'0.4vw'}
                />
              </Styled.Container>
            </Styled.Row>

            <Styled.Container width={'29.306vw'} margin={'1.389vw 0 1.5vw 0'}>
              <TextField
                label={'E-mail'}
                disabled={true}
                name={'EMAIL'}
                width={'29.306vw'}
                control={control}
                value={contato?.EMAIL}
                type={'email'}
                spacelabelinput={'0.4vw'}
              />
            </Styled.Container>

            <Styled.Row>
              <OptionsContainerProfiles>
                <SelectInput
                  label={'Perfil'}
                  control={control}
                  options={perfisArray}
                  name={'PERFIL'}
                  initialValue={perfis[0]?.ID}
                  disabled
                  width={'29.306vw'}
                  shrink={true}
                />
              </OptionsContainerProfiles>
            </Styled.Row>

            <Styled.Row>
              <Styled.Container width={'14.958vw'} margin={'1.389vw 0.3vw 0 0'}>
                <TextField
                  label={'Data de Registro'}
                  disabled={true}
                  name={'DATA_REGISTRO'}
                  width={'14.958vw'}
                  control={control}
                  value={dayjs(info)?.format('DD/MM/YYYY HH:mm:ss')}
                  spacelabelinput={'0.4vw'}
                />
              </Styled.Container>
            </Styled.Row>
          </Styled.InformationContainer>

          <Styled.InformationContainer>
            <h3>ENDEREÇO</h3>
            <Styled.Container width={'36vw'} margin={'1.389vw 0 0 0'}>
              <TextField
                label={'Endereço'}
                disabled={true}
                name={'ENDERECO'}
                width={'36vw'}
                control={control}
                value={endereco?.ENDERECO}
                spacelabelinput={'0.4vw'}
              />
            </Styled.Container>

            <Styled.Row>
              <Styled.Container width={'5.208vw'} margin={'1.389vw 0 0 0'}>
                <TextField
                  label={'Número'}
                  disabled={true}
                  name={'NUMERO'}
                  width={'5.208vw'}
                  control={control}
                  value={endereco?.NUMERO}
                  spacelabelinput={'0.4vw'}
                  InputLabelProps={{ shrink: true }}
                />
              </Styled.Container>
              <Styled.Container width={'11.806vw'} margin={'1.389vw 0 0 1.389vw'}>
                <TextField
                  label={'Complemento'}
                  disabled={true}
                  name={'COMPLEMENTO'}
                  width={'11.806vw'}
                  control={control}
                  value={endereco?.COMPLEMENTO}
                  spacelabelinput={'0.4vw'}
                  InputLabelProps={{ shrink: true }}
                />
              </Styled.Container>
              <Styled.Container width={'16.3vw'} margin={'1.389vw 0 0 1.389vw'}>
                <TextField
                  label={'Bairro'}
                  disabled={true}
                  name={'BAIRRO'}
                  width={'16.3vw'}
                  control={control}
                  value={endereco?.BAIRRO}
                  spacelabelinput={'0.4vw'}
                />
              </Styled.Container>
            </Styled.Row>

            <Styled.Row>
              <Styled.Container width={'20.806vw'} margin={'1.389vw 0 0 0'}>
                <TextField
                  label={'Cidade'}
                  disabled={true}
                  name={'CIDADE'}
                  width={'18.3vw'}
                  control={control}
                  value={endereco?.CIDADE}
                  spacelabelinput={'0.4vw'}
                />
              </Styled.Container>
              <OptionsContainer>
                <SelectInput
                  label={'UF'}
                  control={control}
                  options={brasilStateOptions}
                  name={'UF'}
                  initialValue={endereco?.UF}
                  disabled
                  width={'6vw'}
                  shrink={true}
                />
              </OptionsContainer>
            </Styled.Row>

            <Styled.Container width={'20.806vw'} margin={'1.389vw 0 0 0'}>
              <TextField
                label={'Cep'}
                disabled={true}
                name={'CEP'}
                width={'20.806vw'}
                control={control}
                value={endereco?.CEP}
                spacelabelinput={'0.4vw'}
                InputLabelProps={{ shrink: true }}
              />
            </Styled.Container>
          </Styled.InformationContainer>
        </form>
      )}
    </>
  );
}
